import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Select, InputNumber, DatePicker } from "antd";
import { Row, Col } from "antd";
import { Table, Spin } from "antd";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import { dispatch } from "store/index";
import { useSelector } from "react-redux";
import { AllActivityLeadNotes, CreateActivityLeadNotes } from "store/action/index";
import { AllNotesActivitiesColumns } from "components/columns/columns";

const AllNotes = ({ activityLeadsNotesall, currentLead, leadFeildDisabledCond }) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const selector = useSelector((state) => state);
    const [form] = Form.useForm();
    const { Option } = Select;
    const [startDate, setStartDate] = useState(null);
    const handleStartDateChange = (date) => {
        setStartDate(date);
    };


    useEffect(() => {
        dispatch(AllActivityLeadNotes({ setLoading: setLoading, params: { lead_id: currentLead?.lead?.id, per_page: 'all' } }));
    }, [currentLead]);


    useEffect(() => {
    }, [activityLeadsNotesall]);

    useEffect(() => {
    }, [currentLead]);








    const columns = AllNotesActivitiesColumns();


    const onFinish = (values) => {
        const payload = {
            note: values.note,
            activity_campaign_id: currentLead?.activity_setting_id,
            lead_id: currentLead?.lead_id,
        };

        dispatch(CreateActivityLeadNotes({ fieldData: payload, params: { lead_id: currentLead?.lead?.id, per_page: 'all' } }));
        message.success("Call setting created successfully!");
    };



    return (
        <>
            <MainCard style={{ marginBottom: "10px" }}>
                <Spin spinning={loading}>
                    <Table
                        dataSource={[...(activityLeadsNotesall?.data ?? [])].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))}
                        columns={columns}
                        style={{ marginTop: 25 }}
                    />
                </Spin>
            </MainCard>
            <MainCard>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="note"
                                label="Note"
                                tooltip="Enter Note"
                                rules={[{ required: true, message: "Please enter Note" }]}
                            >
                                <Input.TextArea max={500} />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Form.Item>
                        <Button disabled={leadFeildDisabledCond} type="primary" htmlType="submit">
                            Add Note
                        </Button>
                    </Form.Item>
                </Form>
            </MainCard>
        </>
    )
}




const mapStateToProps = (state) => {
    return {
        activityLeadsNotesall: state?.ActivityLeadsNotesRedc?.activityLeadsNotesall,
    };
};

export default connect(mapStateToProps)(AllNotes);
