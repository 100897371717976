import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { dispatch } from "store/index";
import { AddCallLeadActivity, FetchCallActivity } from "store/action/index";

import {
  Layout,
  Menu,
  Button,
  Form,
  Input,
  Select,
  Typography,
  Card,
  Timeline,
  message,
  Space,
  Modal,
  Checkbox,
} from "antd";
import {
  PhoneOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import AllNotes from "./AllNotes"
import SipjsProvider from "components/sipjs-connection/SipProvider";

const { Header, Sider, Content } = Layout;
const { Title } = Typography;

const CRMLeadDetails = ({ activityCall }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const { campaign } = location.state || {};
  const [currentLead, setCurrentLead] = useState({});
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(0);
  const [isCallActive, setIsCallActive] = useState(false);
  const [hasCallEnded, setHasCallEnded] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [leadFeildDisabledCond, setLeadFieldDisabledCond] = useState(true);
  const [activeTab, setActiveTab] = useState("1"); // Track active tab
  const [followUpOptions, setFollowUpOptions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [params, setparams] = useState({ id: campaign?.id });
  const [remainingLeads, setRemainingLeads] = useState([])
  const [alreadySelectLead, setAlreadySelectLead] = useState(null)

  // useEffect(() => { console.log("UpdateRuntimeSTate", leadFeildDisabledCond) }, [leadFeildDisabledCond])

  useEffect(() => {
    const data = activityCall?.data?.data?.[0];
    if (data && !loading) {
      const call_remain = [...(data?.activity_history.filter(v => v?.status == '0')), ...(data?.activity_history.filter(v => v?.status == '2')),]
      // const total_leads = Array.isArray(data?.activity_history) ? data?.activity_history.length : 0;
      // const call_done = data?.activity_history.filter(v => v?.status == '1');
      setRemainingLeads(call_remain)
      // console.log("Received campaign:", data?.activity_history);
      if (call_remain?.[0]) {

        if (!alreadySelectLead) { setCurrentLead(call_remain?.[0] ?? {}); }

      } else {
        navigate('/activities/call-manager')
      }
      // console.log("Received campaign:", data, call_remain);
      // console.log("Check Update Data", activityCall);
      // console.log("Call Remain Data", call_remain);
      // console.log("dfdfdfdfere", campaign);
    }

    // console.log("Actttttssscampaign", campaign)
  }, [activityCall, campaign, loading]);

  useEffect(() => {
    if (params?.id) {
      dispatch(FetchCallActivity({ params: { activity_id: 2, ...params }, setLoading: setLoading }));
    }
  }, [params]);

  useEffect(() => {
    let interval = null;
    if (isCallActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isCallActive]);

  const handleStartCall = () => {
    setIsCallActive(true);
    setHasCallEnded(false);
  };

  const handleHangUp = () => {
    setIsCallActive(false);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    setHasCallEnded(true);

  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setHasCallEnded(true);
  };

  const handleFollowUpChange = () => {
    setFollowUpOptions((prev) => (!prev));
  };



  const handleNextCallLeadAgain = async () => {
    try {
      const values = await form.validateFields();

      const additionalFields = campaign?.questions?.map((question) => {
        const answer = values[question.question];
        // console.log('hhhhhhhhhh', answer)
        if (answer && answer != 'undefined') {

          return {
            question_id: question?.id,
            answer: Array.isArray(answer) ? answer : [answer],
          }
        }


      }).filter(Boolean);

      // console.log('hhhhhhhhhh', additionalFields);

      const payload = {
        lead_id: currentLead?.lead_id,
        campaign_settings_id: campaign?.id,
        additionalFields,
        campaign_id: campaign?.id,
        NextCall: true,
        history_id: currentLead?.id,
        ...(followUpOptions && { follow_up: 1 })
      };

      await dispatch(AddCallLeadActivity({ fieldData: payload }));

      // Force a refresh of the data
      if (params?.id) {
        await dispatch(FetchCallActivity({ params: { ...params, activity_id: 2 }, setLoading: setLoading }));
      }

      setIsModalVisible(false)
      setFollowUpOptions(false)
      // Optionally clear the form or reset specific fields
      form.resetFields();

    } catch (error) {
      console.error('Form validation or submission error:', error);
      message.error('Please fill in all required fields before proceeding to next call');
    }
  };

  const onFinish = (values) => {
    const additionalFields = campaign?.questions?.map((question) => {
      const answer = values[question.question];
      // console.log('hhhhhhhhhh', answer)
      if (answer && answer != 'undefined') {

        return {
          question_id: question?.id,
          answer: Array.isArray(answer) ? answer : String(answer),
        }
      }
    }).filter(Boolean);

    // console.log('hhhhhhhhhh', additionalFields)

    const payload = {
      lead_id: currentLead?.lead_id,
      campaign_settings_id: campaign?.id,
      additionalFields,
      // campaign_id: campaign?.id,
      NextCall: false,
      history_id: currentLead?.id
    };
    dispatch(AddCallLeadActivity({ fieldData: payload }));
  };

  useEffect(() => {
    if (activityCall?.data?.data?.[0]) {
      const data = activityCall.data?.data[0];
      const call_remain = [...(data?.activity_history.filter(v => v?.status == '0')), ...(data?.activity_history.filter(v => v?.status == '2')),]?.[0]
      // const call_remain = data?.activity_history?.find(v => v?.status === '0' || v?.status == '2');

      // console.log("Current call_remain:", call_remain);

      if (call_remain?.lead) {
        // Update currentLead state
        if (!alreadySelectLead) {
          setCurrentLead(call_remain);

          // Explicitly reset form with new values
          form.setFieldsValue({
            Name: call_remain.lead.name,
            email: call_remain.lead.email,
            phone: call_remain.lead.phone,
          });
        }
        // console.log("Form updated with:", {
        //   Name: call_remain.lead.name,
        //   email: call_remain.lead.email,
        //   phone: call_remain.lead.phone
        // });
      }
    }
  }, [activityCall, form]);


  const onLeadsSelect = (obj) => {
    setAlreadySelectLead(obj)
    setCurrentLead(obj)

    form.setFieldsValue({
      Name: obj?.lead?.name,
      email: obj?.lead?.email,
      phone: obj?.lead?.phone,
    });
  }

  // Content components for each tab
  const LeadQuestionsContent = () => (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-8">
          <Form form={form} onFinish={onFinish} layout="vertical">
            <div className="row">
              <div className="col-md-6">
                <Form.Item label="Name" name="Name">
                  <Input disabled />
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item label="Email" name="email">
                  <Input disabled />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Item label="Phone No." name="phone">
                  <Input disabled />
                </Form.Item>
              </div>
            </div>
            <div className='row'>
              <h2>Script</h2>
              <p dangerouslySetInnerHTML={{ __html: campaign?.call_template?.description }}></p>
              <hr />
            </div>

            <div className="row">

              <h4 style={{ color: "#0055A2", marginBottom: "10px" }}>Questions & Answers</h4>
              {campaign && campaign?.questions && campaign?.questions.length > 0 && (
                <div className="row">
                  {campaign.questions.map((question, index) => {
                    const options = JSON.parse(question.values);
                    return (
                      <div className="col-md-6" key={index}>
                        <Form.Item label={question.question} name={question.question}>
                          {question.type === "Single Select" ? (
                            <Select disabled={leadFeildDisabledCond} placeholder="Select an option">
                              {options.map((option, i) => (
                                <Select.Option key={i} value={option}>
                                  {option}
                                </Select.Option>
                              ))}
                            </Select>
                          ) : question.type === "Multiple Select" ? (
                            <Select disabled={leadFeildDisabledCond} mode="multiple" placeholder="Select options">
                              {options.map((option, i) => (
                                <Select.Option key={i} value={option}>
                                  {option}
                                </Select.Option>
                              ))}
                            </Select>
                          ) : question.type === "Text" ? (
                            <Input disabled={leadFeildDisabledCond} placeholder="Enter text" />
                          ) : null}
                        </Form.Item>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {/* <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Space>
            </Form.Item> */}
          </Form>

          <div className="row" style={{ marginTop: "9px" }}>
            <h3 style={{ marginBottom: "13px" }}>Notes</h3>
            <AllNotes leadFeildDisabledCond={leadFeildDisabledCond} campaign={campaign} currentLead={currentLead} />
          </div>
        </div>
        <div className="col-md-4">
          <CallLogsContent />
        </div>
      </div>
    </div>
  );

  const CallLogsContent = () => (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Card title="Call Logs">
            <Timeline>
              {Array.isArray(currentLead?.call_log) ? currentLead?.call_log?.slice().reverse().map(v => <Timeline.Item dot={<PhoneOutlined style={{ fontSize: "16px" }} />}>
                <b> {v?.call_status}</b>
                {v?.call_started_on && !v?.call_duration && <p>Call Start: {v?.call_started_on}</p>}
                {v?.call_ended_on && <p> Call End: {v?.call_ended_on}</p>}
                {v?.call_duration && <small>Duration {v?.call_duration}</small>}
              </Timeline.Item>
              ) : <></>}
            </Timeline>
          </Card>
        </div>
      </div>
    </div>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider width={250} theme="light">
        <div style={{ padding: "20px" }}>
          <Space direction="vertical" style={{ width: "100%", marginTop: "20px" }}>
            {/* <Select placeholder="Select Leads" value={currentLead?.id} style={{ width: "100%", marginTop: "20px" }} onChange={(v, v1) => onLeadsSelect(v1?.obj)}>
              {remainingLeads.map((option, i) => (
                <Select.Option key={i} value={option?.id} obj={option}>
                  {option?.lead?.name}
                </Select.Option>
              ))}
            </Select> */}
            <Button type="primary" onClick={handleHangUp} block>
              {remainingLeads?.length === 1 ? 'Save & exit' : 'Next Lead'}
            </Button>
            {activityCall?.data?.data?.[0] && <SipjsProvider currentLead={currentLead} setLeadFieldDisabledCond={setLeadFieldDisabledCond} allData={activityCall?.data?.data?.[0]} />}
          </Space>
        </div>
      </Sider>

      <Modal
        title="Follow-up Actions"
        visible={isModalVisible}
        style={{ marginTop: "40px" }}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Space direction="vertical" style={{ width: "100%", marginTop: "16px" }}>
          <Checkbox
            checked={followUpOptions}
            onChange={() => handleFollowUpChange()}
          >
            Schedule Follow-up Call
          </Checkbox>
        </Space>
        <Button className='mt-4' onClick={handleNextCallLeadAgain}>
          Next
        </Button>
      </Modal>

      <Layout>
        <Header style={{ background: "#fff", padding: 0 }}>
        </Header>
        <Content style={{ margin: "24px 16px", padding: 24, background: "#fff", position: "relative" }}>
          <LeadQuestionsContent />
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    activityCall: state?.ActivityCallRedc?.activityCall,
  };
};

export default connect(mapStateToProps)(CRMLeadDetails);