import { combineReducers } from "redux";
import menu from "./menu";
import CreateRolssseeeReducer from "./createrole";
import ViewAllRolesReduncerre from "./viewallrole";
import AssignPermisionsReducer from "./assignpermissions";
import GetAllPermissionReducer from "./getallassignpermissions";
import ViewAllRedUsers from "./viewAllUsers";
import EditUserDataReducer from "./edituser";
import GetRolePermissions from "./rolePermissions";
import AllGetAssignPer from "./assignpermission/allpermissionassign";
import GetAllUsers from "./users/getAllusers";
import AllCompainReducer from "./compaigns/allcompainsreducer";
import AllLeadsReducer from "./leads/allleadsreducer";
import AllGetTagsReducer from "./tagsallget";
import AllActivities from "./activities/allactivities";
import AllLeadsSelectReducer from "./leads/allleadsselectreducer";
import AllLeadDetailsDataRed from "./leads/leadsdetails";
import EmailTemplatesReducer from "./templates/emailtemplates";
import WhatsappTemplatesReducer from "./templates/whatsapptemplate";
import AllEmailSettingReducer from "./email/allemailsetting";
import AllWhatsappSettingReducer from "./whatsapp/allwhatsappsetting";
import AllCallSettingReducer from "./call/allCallSetting";
import AllSmsDataReducer from "./allsmsreducer";
import AllTeamsReducer from "./teams/allTeams";
import AllCallScriptReducer from "./callscript/allcallscript";
import AllCallLogsReducer from "./allLogs/CallLogs";
import AllSmsLogsReducer from "./allLogs/SmsLogs";
import AllEmailLogsReducer from "./allLogs/SmsLogs";
import AllWhatsAppLogsReducer from "./allLogs/WhatsApplogs";
import AllSmsTemplatesReducer from "./smstemplates/AllSmsTemplates";
import CampaignAllActivitiesRedc from "./activities/campaignsAllActivities";
import ActivityDetailRedc from "./activities/activityDetails";
import LeadHistoryAllRedcucer from "./leads/leadhistory";
import AllWhatsappChannnel from "./whatsappchannel";
import ActivityCallRedc from "./activities/callActivity";
import GetAllCasCadesReducers from "./allcascades";
import UpdateReducerWhatsappTemplateSUbjid from "./whatsappupdatedtemplatesubjectid";
import ActivityLeadsNotesRedc from "./activities/activityleadsNotes";
import GetAllLogsReducer from "./allLogs/allLogs";
import GetAllLeadsCallLogsReducer from "./allLogs/allleadcallLogs";
import AllDashboardCountsReducer from "./dashboardcounts/allDashboardCounts";



// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  ActivityDetailRedc,
  GetAllUsers,
  menu,
  CreateRolssseeeReducer,
  ViewAllRolesReduncerre,
  AssignPermisionsReducer,
  GetAllPermissionReducer,
  ViewAllRedUsers,
  EditUserDataReducer,
  GetRolePermissions,
  AllGetAssignPer,
  AllCompainReducer,
  AllLeadsReducer,
  AllGetTagsReducer,
  AllActivities,
  AllLeadsSelectReducer,
  AllLeadDetailsDataRed,
  EmailTemplatesReducer,
  WhatsappTemplatesReducer,
  AllEmailSettingReducer,
  AllWhatsappSettingReducer,
  AllSmsDataReducer,
  GetAllCasCadesReducers,
  AllTeamsReducer,
  AllCallScriptReducer,
  AllCallLogsReducer,
  AllSmsLogsReducer,
  AllEmailLogsReducer,
  AllWhatsAppLogsReducer,
  AllSmsTemplatesReducer,
  CampaignAllActivitiesRedc,
  LeadHistoryAllRedcucer,
  AllWhatsappChannnel,
  ActivityCallRedc,
  UpdateReducerWhatsappTemplateSUbjid,
  AllCallSettingReducer,
  ActivityLeadsNotesRedc,
  GetAllLogsReducer,
  GetAllLeadsCallLogsReducer,
  AllDashboardCountsReducer,
});

export default reducers;
